import * as React from "react";
import { cva } from "class-variance-authority";

import { cn } from "@/components/utils";

const badgeVariants = cva(
	"rounded-full border font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 truncate",
	{
		variants: {
			variant: {
				default: "border-transparent bg-primary text-primary-foreground",
				background: "border-transparent bg-background text-foreground",
				"outline-background": "border-background text-background",
				secondary: "border-transparent bg-secondary text-secondary-foreground",
				destructive:
					"border-transparent bg-destructive text-destructive-foreground",
				outline: "text-foreground",
				"outline-primary": "border-primary text-primary",
				"outline-orange": "border-orange-500 text-orange-500",
			},
			size: {
				default: "px-3 py-1 text-sm",
				sm: "px-2 py-1 text-[11px]",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);

function Badge({ className, variant, size = "default", ...props }) {
	return (
		<div
			className={cn(badgeVariants({ variant, size }), className)}
			{...props}
		/>
	);
}

export { Badge, badgeVariants };
